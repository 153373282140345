/* components/Modal.module.css */
.modalBackdrop {
  position: fixed;
  margin-top: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;

  &.noPadding {
    padding: 0; // No padding
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}

.modalBackdrop.open {
  opacity: 1;
}

.modal.open {
  // transform: translateY(0);
  transition: all 0.5s ease;
  opacity: 1;
}

.small {
  max-width: 335px;
  width: 100%;
  max-height: 80%;
}

.large {
  width: 720px;
  max-height: 80%;
}

.x-large {
  width: 1150px;
  max-height: 85%;
}

.auto {
  width: auto;
  max-height: 80%;
}

.media-inquery {
  max-width: 900px;
  background: #fff;
  position: relative;
}

.full {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  padding: 0px;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  font-family: 'pangrammedium';

  .link {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    color: #e57200;
    font-size: 15px;
    line-height: 17px;
  }
}

.modalTitle {
  margin: 0;
}

.modalCloseBtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #888;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 999;
}

.modalBody {
  overflow-y: auto;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

// .with-border {
//     border-bottom: 1px solid #000;
// }
.similar-modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0px;
}

.modal.center {
  display: flex;
  justify-content: center;
  align-items: center;

  .modalBody {
    width: 100%;
  }
}

.modal.bottom {
  bottom: 0px;
  position: absolute;
  width: 100%;
  max-width: 100%;
  border-radius: 0px;

  .modalBody {
    width: 100%;
  }
}

.video-modal-head {
  flex-direction: column;
  overflow: unset;
  padding: 10px;

  .modalHeader {
    width: 100%;
  }

  .modalCloseBtn {
    position: absolute;
    right: 0px;
    top: -28px;
    left: auto;
    width: 35px;
  }
}

.price-details-modal {
  padding: 0px;
  transition: all 0.5s ease;

  .modalHeader {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    padding: 12px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    color: #515151;
    font-family: 'pangramregular';
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
  }

  .modalCloseBtn {
    position: revert;
    padding: 0px;
    margin-right: 10px;

    img {
      opacity: 52%;
      width: 16px;
    }
  }

  .modalBody {
    padding: 0px 10px;
  }
}

.emi-Paymen-modal {
  padding: 0px;
  border-radius: 0px;
  overflow: revert;
  height: 442px;
  max-height: none;

  .modalHeader {
    font-size: 1.25rem;
    padding: 15px;
    line-height: normal;
    color: #000;
  }

  .modalCloseBtn {
    right: -35px;
    top: -3px;
    left: auto;

    img {
      width: 18px;
      filter: invert(1);
    }
  }
}

.review-image-modal {
  border-radius: 0px;
  padding: 15px;
  max-width: 950px;
  width: 100%;
  height: 362px;

  .modalHeader {
    margin: 0px;

    .modalCloseBtn {
      right: -11px;
      top: -8px;
    }
  }
}

.login-modal {
  border-radius: 0px;
  max-width: 784px;
  width: 100%;
  max-height: 100%;

  .modalHeader {
    margin: 0px;
  }
}

.customer-video-modal {
  padding: 0px;
  position: relative;
  overflow: visible;
  border-radius: 0px;
  max-width: 879px;
  width: 100%;

  .modalHeader {
    font-size: 0px;
    padding: 0px;
    margin: 0px;
    line-height: 0px;
  }

  .modalCloseBtn {
    position: absolute;
    right: 0px;
    top: -24px;

    img {
      filter: invert(1) !important;
    }
  }
}

.tc-modal {
  max-width: 950px;
  border-radius: 2px;
  overflow: revert;

  .modalHeader {
    margin: 0px;
    padding: 0px;
  }

  .modalCloseBtn {
    right: -34px;
    top: -46px;

    img {
      filter: invert(1);
    }
  }
}

.see-store-modal {
  padding: 38px 20px 20px;
  width: 850px;

  .modalHeader {
    .modalTitle {
      display: none;
    }
  }

  .modalCloseBtn {
    right: -16px;
    top: -31px;
  }
}

.write-review-modal {
  max-width: 347px;
  width: 100%;
  border-radius: 4px;
  padding: 25px;
  overflow: revert;

  .modalHeader {
    margin: 0px;
  }

  .modalCloseBtn {
    right: -60px;
    top: -26px;

    img {
      filter: invert(1);
      width: 21px;
      height: 21px;
    }
  }
}

.request-call-modal,
.thank-modal {
  max-width: 500px;
  width: 100%;
  padding: 25px 20px;

  .modalCloseBtn {
    right: -13px;
    top: -17px;

    img {
      width: 14px;
    }
  }

  .modalBody {
    overflow: revert;
  }
}

.thank-modal {
  max-width: 5px;
}

.ship-add-modal {
  .modalHeader {
    margin: 0px;
  }

  .modalCloseBtn {
    right: -13px;
    top: -10px;
  }
}

.delivery-address-modal {
  max-width: 580px;
  width: 100%;
  border-radius: 0px;
  padding: 20px 30px;
  overflow: unset;

  .modalHeader {
    margin: 0px;
  }

  .modalCloseBtn {
    right: -35px;
    top: -44px;

    img {
      filter: invert(1);
    }
  }
}

.quick-view-produ-modal {
  max-width: 1240px;
  max-height: 756px;
  min-height: 681px;
  overflow-y: scroll !important;
}

.save-address-modal {
  overflow: unset;
  padding: 15px;
  border-radius: 0px;
  max-width: 371px;
  max-height: 100%;
  width: 100%;

  .modalCloseBtn {
    top: -44px;
    right: -23px;

    img {
      filter: invert(1);
    }
  }
}

.product-gallery-modal {
  .modalHeader {
    display: none;
  }
}

.price-drop-modal {
  overflow: unset;
  padding: 20px;
  max-width: 450px;
  max-height: 100%;
  width: 100%;
  border-radius: 4px;

  .modalCloseBtn {
    top: -46px;
    right: -16px;

    img {
      filter: invert(1);
      width: 20px;
      height: 20px;
    }
  }
}

.fast-delivery-wrpr {
  display: block;
  padding: 0;
  background: #fff;
  border-radius: 10px;
  position: relative;
  margin: 0 auto;
  width: 560px;
  max-width: 560px;
  text-align: center;
  max-height: 100%;
  overflow: unset;
  .modalHeader {
    margin: 0px;
  }

  .modalCloseBtn {
    top: -25px;
    right: 0px;
    filter: invert(1);
  }
}

.create-your-own-modal {
  overflow: unset;
  padding: 0px;
  border-radius: 0px;
  max-width: 371px;
  max-height: 100%;
  width: 100%;

  .modalHeader {
    margin: 0px;
  }

  .modalCloseBtn {
    top: -26px;
    right: auto;
    left: 5px;

    img {
      filter: invert(1);
      width: 20px;
      height: 20px;
    }
  }
}

.happy-customers-modal {
  max-width: 596px;
  border-radius: 0px;
  padding: 50px 20px 20px;
  border-radius: 5px;

  .modalCloseBtn {
    right: -5px;
    top: -36px;
  }

  .modalBody {
    overflow: hidden;
  }
}

.confirmation-modal {
  padding: 20px;
  max-width: 450px;
  border-radius: 4px;
  overflow: revert;
  .modalHeader {
    margin: 0pc;
  }
  .modalCloseBtn {
    position: absolute;
    right: -20px;
    top: -44px;
    img {
      filter: invert(1);
    }
  }
}

@media (max-width: 767px) {
  .login-modal {
    width: 100% !important;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 0px;
    max-height: 100%;
    max-width: 100%;

    .modalCloseBtn {
      right: auto;
      left: 20px;
      top: 15px;
      padding: 0px;
    }
  }

  .available-color-finish {
    padding: 0px;

    .modalHeader {
      font-size: 15px;
      letter-spacing: 0.02em;
      line-height: 1.15;
      padding: 12px 10px 12px 37px;
      border-bottom: 1px solid #e5e5e5;
    }

    .modalCloseBtn {
      position: absolute;
      top: 6px;
      left: 7px; // Or 0px, depending on your design
      right: auto;
    
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0; // Reset any browser default padding
      margin: 0;
    
      width: 30px; // Set fixed size if needed
      height: 30px;
    
      img {
        width: 15px;
        height: 15px;
        display: block; // Avoid inline spacing issues
      }
    }

    .modalBody {
      padding: 15px 10px 0px;
    }
  }

  .happy-customers-modal {
    max-width: 350px !important;
    border-radius: 0px;
    padding: 0;
    overflow: inherit;
    .modalHeader {
      margin: 0px;
    }

    .modalCloseBtn {
      right: -5px;
      top: -27px;
      img {
        filter: invert(1);
      }
    }

    .modalBody {
      overflow: hidden;
    }
  }
}

@media (max-width: 575px) {
  .modal {
    max-width: 96%;
  }

  .login-modal {
    width: 100% !important;
    max-width: 100%;
    max-height: 100%;
  }

  .video-modal-head {
    max-width: 100%;
  }

  .product-gallery-modal {
    max-width: 100%;
  }

  .write-review-modal {
    .modalCloseBtn {
      right: -24px;
      top: -52px;
    }
  }
}

.modal {
  animation: slide-in 0.4s ease;
  box-shadow: none;
}

@keyframes slide-in {
  from {
    opacity: 0.4;
    transform: translateY(-20px);
    transition: opacity 0.3s ease;
  }
  to {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease;
  }
}

@media (max-width: 1600px) {
  .quick-view-produ-modal {
    max-width: 1240px;
    max-height: calc(100vh - 30px) !important;
    overflow-y: scroll !important;
  }
}
